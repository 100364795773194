@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-Light.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-Regular.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-Italic.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-Bold.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-Black.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "LEGOTypewell";
  src: url("./assets/fonts/LEGOTypewell-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/LEGOTypewell-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=sentient@200,201,300,301,400,401,500,501,700,701&display=swap");

body {
  font-family: "Manrope", sans-serif;
}
